import { useEffect, useRef } from 'react';
import { isEqual } from 'lodash';
import { initializeApp } from 'firebase/app';
export var initFirebase = function () { return initializeApp({
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MSG_SENDER,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
}); };
export var useDeepEffect = function (fn, deps) {
    var isFirst = useRef(true);
    var prevDeps = useRef(deps);
    useEffect(function () {
        var isSame = prevDeps.current.every(function (obj, index) {
            return isEqual(obj, deps[index]);
        });
        if (isFirst.current || !isSame) {
            fn();
        }
        isFirst.current = false;
        prevDeps.current = deps;
    }, [deps, fn]);
};
export var getItemFromLocalStorage = function (key, defaultVal) {
    var item = localStorage.getItem(key);
    return item ? JSON.parse(item) : defaultVal;
};
// the party's name is dependent on the subdomain
export var getPartyId = function () {
    // no ports
    return window.location.host.split('.')[0].split(':')[0];
};
export var formattedUserName = function (_a) {
    var firstName = _a.firstName, lastName = _a.lastName, id = _a.id;
    return firstName
        ? firstName + " " + lastName.charAt(0).toUpperCase()
        : "Anon_" + id.slice(-5).toLowerCase();
};
export var formattedUserYear = function (year) {
    if (!year) {
        return '';
    }
    return year.toString().slice(2);
};
