var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { getFirestore, doc, setDoc, getDoc } from 'firebase/firestore';
import { getStorage, ref, uploadBytes } from 'firebase/storage';
import { initFirebase } from 'utils/helpers';
initFirebase();
var db = getFirestore();
var storage = getStorage();
// handles "add" as well
export var updateUser = function (userId, attributes) {
    return setDoc(doc(db, 'users', userId), __assign({}, attributes));
};
export var getUserData = function (userId) {
    var userRef = doc(db, 'users', userId);
    return getDoc(userRef);
};
export var uploadUserPhoto = function (userId, photo) {
    var photoRef = ref(storage, "profiles/" + userId);
    return uploadBytes(photoRef, photo);
};
