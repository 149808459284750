var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState, useContext, useEffect, useRef } from 'react';
import { Link } from '@curi/react-dom';
import { getDownloadURL } from 'firebase/storage';
import toast from 'react-hot-toast';
import { ProfileContext } from '__state/Profile';
import { updateUser, getPartyData, uploadUserPhoto } from 'services';
import HeaderAuth from 'components/HeaderAuth';
import ProfilePhoto from 'components/ProfilePhoto';
import './RegisterPhoto.scss';
var baseClass = 'page__register-profile';
var RegisterPhoto = function () {
    var _a = useContext(ProfileContext), profile = _a.profile, setProfile = _a.setProfile;
    var imageRef = useRef(null);
    var _b = useState(), party = _b[0], setParty = _b[1];
    useEffect(function () {
        var getParty = function () { return __awaiter(void 0, void 0, void 0, function () {
            var partySnap, partyData, error_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, getPartyData()];
                    case 1:
                        partySnap = _a.sent();
                        partyData = partySnap.data();
                        setParty(partyData);
                        return [3 /*break*/, 3];
                    case 2:
                        error_1 = _a.sent();
                        console.error(error_1);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        }); };
        getParty();
    }, []);
    var openPhotos = function () {
        var _a;
        // @ts-ignore
        (_a = imageRef.current) === null || _a === void 0 ? void 0 : _a.click();
    };
    var savePhoto = function (e) { return __awaiter(void 0, void 0, void 0, function () {
        var photo, ref, url, userProfile, error_2;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!profile || !e.target.files) {
                        toast.error('Something went wrong saving your photo. Please try again or contact us.');
                        return [2 /*return*/, false];
                    }
                    photo = e.target.files[0];
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 5, , 6]);
                    return [4 /*yield*/, uploadUserPhoto(profile.id, photo)];
                case 2:
                    ref = (_a.sent()).ref;
                    return [4 /*yield*/, getDownloadURL(ref)];
                case 3:
                    url = _a.sent();
                    userProfile = __assign(__assign({}, profile), { photoURL: url });
                    return [4 /*yield*/, updateUser(profile.id, userProfile)];
                case 4:
                    _a.sent();
                    setProfile(userProfile);
                    return [3 /*break*/, 6];
                case 5:
                    error_2 = _a.sent();
                    console.error(error_2);
                    return [3 /*break*/, 6];
                case 6: return [2 /*return*/];
            }
        });
    }); };
    if (!party) {
        return null;
    }
    return (_jsxs("div", __assign({ className: baseClass }, { children: [_jsx(HeaderAuth, { logo: party.headerImage, color: party.headerColor }, void 0), _jsx("div", __assign({ className: baseClass + "__title" }, { children: _jsx("h1", { children: "Profile Photo" }, void 0) }), void 0), _jsxs("div", __assign({ className: baseClass + "__page-info" }, { children: [_jsx("span", { children: _jsx("b", { children: "Step 3 of 3" }, void 0) }, void 0), _jsx(Link, __assign({ name: "Party" }, { children: "Skip" }), void 0)] }), void 0), _jsxs("div", __assign({ className: baseClass + "__form" }, { children: [_jsx("div", __assign({ className: "form-row" }, { children: _jsxs("div", __assign({ role: "button", onClick: openPhotos, className: baseClass + "__change-photo" }, { children: [profile && (_jsx(ProfilePhoto, { photoURL: profile.photoURL || party.defaultUserImage, firstName: profile.firstName, lastName: profile.lastName, size: "large" }, void 0)), _jsx("span", { children: "Upload" }, void 0), _jsx("input", { ref: imageRef, type: "file", accept: "image/*", onChange: savePhoto }, void 0)] }), void 0) }), void 0), _jsx("div", __assign({ className: "form-row" }, { children: _jsx(Link, __assign({ className: "go-to-party", name: "Party" }, { children: "Back to Party" }), void 0) }), void 0)] }), void 0)] }), void 0));
};
export default RegisterPhoto;
