var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Link } from '@curi/react-dom';
import './AuthPageNav.scss';
var AuthPageNav = function (_a) {
    var authPage = _a.authPage;
    if (authPage === 'login') {
        return (_jsxs("div", __assign({ className: "component__page-nav login" }, { children: [_jsx(Link, __assign({ name: "Party" }, { children: "Back to Party" }), void 0), _jsx(Link, __assign({ name: "Register" }, { children: "Go to Register" }), void 0), _jsx(Link, __assign({ name: "ResetPassword" }, { children: "Reset Password" }), void 0)] }), void 0));
    }
    else if (authPage === 'register') {
        return (_jsxs("div", __assign({ className: "component__page-nav" }, { children: [_jsx(Link, __assign({ name: "Party" }, { children: "Back to Party" }), void 0), _jsx(Link, __assign({ name: "Login" }, { children: "Go to Log In" }), void 0)] }), void 0));
    }
    else if (authPage === 'in-app') {
        return (_jsx("div", __assign({ className: "component__page-nav single" }, { children: _jsx(Link, __assign({ name: "Party" }, { children: "Back to Party" }), void 0) }), void 0));
    }
    return null;
};
export default AuthPageNav;
