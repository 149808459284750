var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { getFirestore, doc, addDoc, setDoc, deleteDoc, getDoc, getDocs, updateDoc, collection, query, orderBy, onSnapshot, serverTimestamp, increment, where, endBefore, limitToLast, } from "firebase/firestore";
import { initFirebase, getPartyId } from "utils/helpers";
initFirebase();
var db = getFirestore();
var watchPartyId = getPartyId();
var firstVisible = null;
var MSG_LIMIT = 30;
//============== PARTY ==================//
export var subscribeToPartyInfo = function (callback) {
    return onSnapshot(doc(db, 'parties', watchPartyId), function (doc) {
        var _a = doc.data() || {}, created = _a.created, name = _a.name, membersCount = _a.membersCount, headerImage = _a.headerImage, headerColor = _a.headerColor, useSocialLogin = _a.useSocialLogin, defaultUserImage = _a.defaultUserImage, showHeadingInChat = _a.showHeadingInChat, defaultGifKeyword = _a.defaultGifKeyword;
        callback({
            name: name,
            membersCount: membersCount,
            headerImage: headerImage,
            headerColor: headerColor,
            defaultUserImage: defaultUserImage,
            useSocialLogin: useSocialLogin,
            showHeadingInChat: showHeadingInChat,
            defaultGifKeyword: defaultGifKeyword,
            created: new Date(created.seconds * 1000),
        });
    });
};
export var getPartyData = function () {
    var partyRef = doc(db, 'parties', watchPartyId);
    return getDoc(partyRef);
};
export var getPartyMembers = function () {
    var q = query(collection(db, 'users'), where('parties', 'array-contains', watchPartyId));
    return getDocs(q);
};
export var increasePartyCount = function () {
    var partyRef = doc(db, 'parties', watchPartyId);
    return updateDoc(partyRef, {
        membersCount: increment(1)
    });
};
//============== MESSAGES ==================//
export var subscribeToPartyMessages = function (callback) {
    var msgRef = collection(db, 'parties', watchPartyId, 'messages');
    var msgQuery = query(msgRef, orderBy('sent', 'asc'), limitToLast(MSG_LIMIT));
    return onSnapshot(msgQuery, function (snapshot) { return __awaiter(void 0, void 0, void 0, function () {
        var newMessages;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    newMessages = [];
                    if (!firstVisible) return [3 /*break*/, 1];
                    snapshot.docChanges().forEach(function (change) {
                        var _a = change.doc.data(), sent = _a.sent, from = _a.from, text = _a.text, image = _a.image, at = _a.at, isFlagged = _a.isFlagged;
                        if (sent) {
                            newMessages.push(__assign(__assign({}, change.doc.data()), { id: change.doc.id, sent: new Date(sent.seconds * 1000), from: from, text: text, image: image, at: at, isFlagged: isFlagged }));
                        }
                    });
                    return [3 /*break*/, 3];
                case 1: return [4 /*yield*/, formatMessages(snapshot)];
                case 2:
                    newMessages = _a.sent();
                    _a.label = 3;
                case 3:
                    callback(newMessages);
                    return [2 /*return*/];
            }
        });
    }); });
};
export var loadPreviousMessages = function () { return __awaiter(void 0, void 0, void 0, function () {
    var msgRef, msgQuery, snapshot, error_1;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                msgRef = collection(db, 'parties', watchPartyId, 'messages');
                msgQuery = query(msgRef, orderBy('sent', 'asc'), limitToLast(MSG_LIMIT), endBefore(firstVisible));
                _a.label = 1;
            case 1:
                _a.trys.push([1, 3, , 4]);
                return [4 /*yield*/, getDocs(msgQuery)];
            case 2:
                snapshot = _a.sent();
                return [2 /*return*/, formatMessages(snapshot)];
            case 3:
                error_1 = _a.sent();
                throw error_1;
            case 4: return [2 /*return*/];
        }
    });
}); };
export var sendMessage = function (_a) {
    var from = _a.from, text = _a.text, image = _a.image, at = _a.at;
    var data = {
        sent: serverTimestamp(),
        from: from,
    };
    if (text) {
        data.text = text;
    }
    if (image) {
        data.image = image;
    }
    if (at) {
        data.at = at;
    }
    return addDoc(collection(db, 'parties', watchPartyId, 'messages'), data);
};
export var likeOrDislikeMessage = function (_a) {
    var messageId = _a.messageId, isLike = _a.isLike, isNewReaction = _a.isNewReaction, userId = _a.userId;
    return new Promise(function (resolve, reject) { return __awaiter(void 0, void 0, void 0, function () {
        var reactionCollection, reactionCount, messagePath, reactionPath, error_2;
        var _a, _b;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0:
                    reactionCollection = isLike ? 'likes' : 'dislikes';
                    reactionCount = isLike ? 'likeCount' : 'dislikeCount';
                    messagePath = "parties/" + watchPartyId + "/messages/" + messageId;
                    reactionPath = messagePath + "/" + reactionCollection;
                    _c.label = 1;
                case 1:
                    _c.trys.push([1, 6, , 7]);
                    if (!isNewReaction) return [3 /*break*/, 4];
                    return [4 /*yield*/, setDoc(doc(db, reactionPath, userId), {})];
                case 2:
                    _c.sent();
                    return [4 /*yield*/, updateDoc(doc(db, messagePath), (_a = {},
                            _a[reactionCount] = increment(1),
                            _a))];
                case 3:
                    _c.sent();
                    return [3 /*break*/, 5];
                case 4:
                    deleteDoc(doc(db, reactionPath, userId));
                    updateDoc(doc(db, messagePath), (_b = {},
                        _b[reactionCount] = increment(-1),
                        _b));
                    _c.label = 5;
                case 5:
                    resolve(true);
                    return [3 /*break*/, 7];
                case 6:
                    error_2 = _c.sent();
                    reject(error_2);
                    return [3 /*break*/, 7];
                case 7: return [2 /*return*/];
            }
        });
    }); });
};
export var flagMessage = function (messageId) {
    var messagePath = "parties/" + watchPartyId + "/messages/" + messageId;
    return updateDoc(doc(db, messagePath), {
        isFlagged: true,
    });
};
export var getLikesAndDislikes = function (messageId) { return __awaiter(void 0, void 0, void 0, function () {
    var likes, dislikes, likesRef, dislikesRef, likesSnapshot, disikesSnapshot;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                likes = [];
                dislikes = [];
                likesRef = query(collection(db, 'parties', watchPartyId, 'messages', messageId, 'likes'));
                dislikesRef = query(collection(db, 'parties', watchPartyId, 'messages', messageId, 'dislikes'));
                return [4 /*yield*/, getDocs(likesRef)];
            case 1:
                likesSnapshot = _a.sent();
                return [4 /*yield*/, getDocs(dislikesRef)];
            case 2:
                disikesSnapshot = _a.sent();
                likesSnapshot.docs.forEach(function (doc) {
                    likes.push(doc.id);
                });
                disikesSnapshot.docs.forEach(function (doc) {
                    dislikes.push(doc.id);
                });
                return [2 /*return*/, { likes: likes, dislikes: dislikes }];
        }
    });
}); };
//============== PRIVATE ==================//
var formatMessages = function (snapshot) { return __awaiter(void 0, void 0, Promise, function () {
    var newMessages;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                newMessages = [];
                return [4 /*yield*/, Promise.all(snapshot.docs.map(function (doc, index) { return __awaiter(void 0, void 0, void 0, function () {
                        var msg, sent, from, text, image, at, likeCount, dislikeCount, isFlagged;
                        return __generator(this, function (_a) {
                            msg = doc.data();
                            if (index === 0) {
                                firstVisible = doc;
                            }
                            sent = msg.sent, from = msg.from, text = msg.text, image = msg.image, at = msg.at, likeCount = msg.likeCount, dislikeCount = msg.dislikeCount, isFlagged = msg.isFlagged;
                            if (sent) {
                                newMessages.push({
                                    id: doc.id,
                                    sent: new Date(sent.seconds * 1000),
                                    from: from,
                                    text: text,
                                    image: image,
                                    at: at,
                                    likeCount: likeCount,
                                    dislikeCount: dislikeCount,
                                    isFlagged: isFlagged,
                                });
                            }
                            return [2 /*return*/];
                        });
                    }); }))];
            case 1:
                _a.sent();
                return [2 /*return*/, newMessages];
        }
    });
}); };
