var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import './ProfilePhoto.scss';
var baseClass = 'component__profile-photo';
var ProfilePhoto = function (_a) {
    var photoURL = _a.photoURL, firstName = _a.firstName, lastName = _a.lastName, size = _a.size;
    var initials = firstName ? "" + firstName.slice(0, 1) + lastName.slice(0, 1) : '';
    return (_jsx("div", __assign({ className: baseClass }, { children: _jsx("div", __assign({ className: baseClass + "__photo-wrapper " + size, "data-initials": initials }, { children: _jsx("div", { style: { backgroundImage: "url('" + photoURL + "')" } }, void 0) }), void 0) }), void 0));
};
export default ProfilePhoto;
