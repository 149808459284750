var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useState, useEffect, useRef } from 'react';
import { useQuery } from 'react-query';
import { GiphyFetch } from '@giphy/js-fetch-api';
import toast from 'react-hot-toast';
import { sortBy, unionBy } from 'lodash';
import { subscribeToPartyMessages, sendMessage, loadPreviousMessages, flagMessage, } from 'services';
import { useDeepEffect } from 'utils/helpers';
import ContentEditable from 'react-contenteditable';
import ChatMessage from 'components/ChatMessage';
import SendMessageButton from '__assets/images/button__send-message.svg';
import IconFlag from '__assets/images/icon__flag.svg';
;
var Chat = function (_a) {
    var baseClass = _a.baseClass, profile = _a.profile, party = _a.party;
    var chatPageRef = useRef(null);
    var moreMenuRef = useRef(null);
    var _b = useState(''), typedMessage = _b[0], setTypedMessage = _b[1];
    var _c = useState((party === null || party === void 0 ? void 0 : party.defaultGifKeyword) || ''), gifSearch = _c[0], setGifSearch = _c[1];
    var _d = useState(), loadedGifs = _d[0], setLoadedGifs = _d[1];
    var _e = useState(''), selectedGif = _e[0], setSelectedGif = _e[1];
    var _f = useState(false), isGifDrawerOpen = _f[0], setIsGifDrawerOpen = _f[1];
    var _g = useState(false), isSending = _g[0], setIsSending = _g[1];
    var _h = useState(), messages = _h[0], setMessages = _h[1];
    var _j = useState(), newMessages = _j[0], setNewMessages = _j[1];
    var _k = useState([]), flaggedRevealed = _k[0], setFlaggedRevealed = _k[1];
    var _l = useState(true), showLoadPrevious = _l[0], setShowLoadPrevious = _l[1];
    var _m = useState(''), idForMoreMenu = _m[0], setIdForMoreMenu = _m[1];
    var giphy = new GiphyFetch(process.env.REACT_APP_GIPHY_KEY);
    var handleClick = function (e) {
        // @ts-expect-error
        if (moreMenuRef.current && !moreMenuRef.current.contains(e.target)) {
            setIdForMoreMenu('');
        }
    };
    useEffect(function () {
        if (!!idForMoreMenu) {
            document.addEventListener('click', handleClick);
        }
        return function () {
            document.removeEventListener('click', handleClick);
        };
    }, [idForMoreMenu]);
    // get gifs
    useQuery(['gifs', gifSearch], function () {
        return giphy.search(gifSearch || 'sports', {
            rating: 'pg-13',
            limit: 25,
        });
    }, {
        enabled: isGifDrawerOpen,
        refetchOnWindowFocus: false,
        refetchOnMount: false,
        onSuccess: function (data) {
            setLoadedGifs(data);
        }
    });
    var scrollTo = function (where, timeout) {
        if (timeout === void 0) { timeout = 300; }
        setTimeout(function () {
            var chat = document.querySelector("." + baseClass + "__chat-container");
            if (chat) {
                var scrollY = where === 'bottom' ? chat.scrollHeight + 20 : 0;
                chat.scrollTo(0, scrollY);
            }
        }, timeout);
    };
    useEffect(function () {
        var unsubMessages = subscribeToPartyMessages(function (newMsgs) {
            setNewMessages(newMsgs);
        });
        return function () { return unsubMessages(); };
    }, []);
    useDeepEffect(function () {
        if (newMessages) {
            var oldMsgs = messages || [];
            var merged = unionBy(newMessages, oldMsgs, 'id');
            var sorted = sortBy(merged, ['sent']);
            setMessages(sorted);
            if (oldMsgs.length < sorted.length) {
                scrollTo('bottom');
            }
        }
    }, [newMessages]);
    var handlePreviousMessages = function () { return __awaiter(void 0, void 0, void 0, function () {
        var prevMsgs, currentMsgs, error_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, loadPreviousMessages()];
                case 1:
                    prevMsgs = _a.sent();
                    if (prevMsgs.length) {
                        currentMsgs = messages || [];
                        setMessages(__spreadArray(__spreadArray([], prevMsgs, true), currentMsgs, true));
                    }
                    else {
                        setShowLoadPrevious(false);
                    }
                    return [3 /*break*/, 3];
                case 2:
                    error_1 = _a.sent();
                    console.error(error_1);
                    toast.error('Something went wrong retrieving your messages. Please try again or contact us.');
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    var handleGifDrawer = function () {
        if (isGifDrawerOpen) {
            setIsGifDrawerOpen(false);
        }
        else {
            setIsGifDrawerOpen(true);
        }
    };
    var handlePaste = function (e) {
        e.preventDefault();
        var text = e.clipboardData.getData('text');
        setTypedMessage(text);
    };
    var handleNewMessage = function () { return __awaiter(void 0, void 0, void 0, function () {
        var stripped, error_2;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    // shouldn't happen
                    if (!profile) {
                        toast.error('Something went wrong sending your message. Please try again or contact us.');
                        return [2 /*return*/, false];
                    }
                    if (isSending ||
                        (!typedMessage && !selectedGif)) {
                        return [2 /*return*/, false];
                    }
                    setIsSending(true);
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, 4, 5]);
                    stripped = typedMessage
                        .replaceAll('<script>', '')
                        .replaceAll('</script>', '')
                        .trim();
                    return [4 /*yield*/, sendMessage({
                            from: profile.id,
                            text: stripped,
                            image: selectedGif,
                        })];
                case 2:
                    _a.sent();
                    setTypedMessage('');
                    setSelectedGif('');
                    setIsGifDrawerOpen(false);
                    scrollTo('bottom', 0);
                    return [3 /*break*/, 5];
                case 3:
                    error_2 = _a.sent();
                    console.error(error_2);
                    toast.error('Something went wrong sending your message. Please try again or contact us.');
                    return [3 /*break*/, 5];
                case 4:
                    setIsSending(false);
                    return [7 /*endfinally*/];
                case 5: return [2 /*return*/];
            }
        });
    }); };
    var handleFlagMessage = function (e) {
        e.preventDefault();
        flagMessage(idForMoreMenu);
        setIdForMoreMenu('');
    };
    var revealFlaggedMessage = function (messageId) { return function (e) {
        e.preventDefault();
        var revealed = flaggedRevealed;
        revealed.push(messageId);
        setFlaggedRevealed(__spreadArray([], revealed, true));
    }; };
    var renderMessage = function () {
        if (!!selectedGif) {
            return (_jsx("div", __assign({ className: "gif-input" }, { children: _jsxs("div", __assign({ className: "selected-gif" }, { children: [_jsx("img", { alt: "", src: selectedGif }, void 0), _jsx("button", __assign({ onClick: function () { return setSelectedGif(''); } }, { children: "\u2716" }), void 0)] }), void 0) }), void 0));
        }
        return (_jsx(ContentEditable, { html: typedMessage, placeholder: "Message", onFocus: function () { return setIsGifDrawerOpen(false); }, onChange: function (e) { return setTypedMessage(e.target.value); }, onPaste: handlePaste, className: "message-input" }, void 0));
    };
    return (_jsxs(_Fragment, { children: [_jsxs("div", __assign({ className: baseClass + "__chat-container", ref: chatPageRef }, { children: [profile && messages && showLoadPrevious && (_jsx("div", __assign({ className: baseClass + "__load-previous" }, { children: _jsx("button", __assign({ onClick: handlePreviousMessages }, { children: "Load previous messages" }), void 0) }), void 0)), messages &&
                        messages.map(function (m, i) {
                            if (m.isFlagged && !flaggedRevealed.includes(m.id)) {
                                return (_jsxs("p", __assign({ className: "flagged-message" }, { children: ["This message was flagged and has been removed from the chat. If you would like to still view it,\u00A0", _jsx("button", __assign({ onClick: function (e) { return revealFlaggedMessage(m.id)(e); } }, { children: "click here" }), void 0), "."] }), i));
                            }
                            return (_jsx(ChatMessage, { message: m, isLast: i === messages.length - 1, party: party, setIdForMoreMenu: setIdForMoreMenu }, i));
                        })] }), void 0), profile && (_jsxs("div", __assign({ className: baseClass + "__chat-message-box" }, { children: [_jsx("div", __assign({ className: "typing-area" }, { children: renderMessage() }), void 0), _jsxs("div", __assign({ className: "action-area" }, { children: [_jsx("div", __assign({ role: "button", className: "gif-button", onClick: handleGifDrawer }, { children: "GIF" }), void 0), _jsx("div", __assign({ role: "button", className: "send-button", onClick: handleNewMessage }, { children: _jsx("img", { alt: "Send message", src: SendMessageButton }, void 0) }), void 0)] }), void 0)] }), void 0)), isGifDrawerOpen && (_jsxs("div", __assign({ className: baseClass + "__gif-drawer" }, { children: [_jsx(ContentEditable, { html: gifSearch, placeholder: "Search Gifs", onChange: function (e) { return setGifSearch(e.target.value); }, onPaste: handlePaste, className: "gif-input" }, void 0), _jsx("div", __assign({ className: "gif-chooser" }, { children: loadedGifs === null || loadedGifs === void 0 ? void 0 : loadedGifs.data.map(function (_a) {
                            var id = _a.id, _b = _a.images, preview_gif = _b.preview_gif, fixed_height_small = _b.fixed_height_small;
                            if (preview_gif && fixed_height_small) {
                                return (_jsx("div", __assign({ role: "button", className: "gif-image", onClick: function () { return setSelectedGif(fixed_height_small.url); } }, { children: _jsx("img", { alt: "", src: preview_gif.url }, void 0) }), id));
                            }
                        }) }), void 0)] }), void 0)), !!idForMoreMenu && (_jsx("div", __assign({ className: baseClass + "__more-menu" }, { children: _jsx("div", __assign({ ref: moreMenuRef, className: "options" }, { children: _jsxs("button", __assign({ onClick: handleFlagMessage }, { children: [_jsx("img", { alt: "", src: IconFlag }, void 0), "Flag"] }), void 0) }), void 0) }), void 0))] }, void 0));
};
export default Chat;
