var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { createContext, useReducer } from 'react';
import { deleteFromStorage, writeStorage } from '@rehooks/local-storage';
import { getItemFromLocalStorage } from 'utils/helpers';
var initialState = {
    profile: getItemFromLocalStorage('profile', null),
    setProfile: function () { },
};
var actions = {
    SET_PROFILE: 'SET_PROFILE',
};
var reducer = function (state, action) {
    switch (action.type) {
        case actions.SET_PROFILE:
            if (action.profile) {
                writeStorage('profile', action.profile);
            }
            else {
                deleteFromStorage('profile');
            }
            return __assign(__assign({}, state), { profile: action.profile });
        default:
            return state;
    }
};
export var ProfileContext = createContext(initialState);
var ProfileProvider = function (_a) {
    var children = _a.children;
    var _b = useReducer(reducer, initialState), state = _b[0], dispatch = _b[1];
    var value = {
        profile: state.profile,
        setProfile: function (profile) {
            dispatch({ type: actions.SET_PROFILE, profile: profile });
        },
    };
    return _jsx(ProfileContext.Provider, __assign({ value: value }, { children: children }), void 0);
};
export default ProfileProvider;
