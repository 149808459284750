var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Link } from '@curi/react-dom';
import './LoginPopover.scss';
var baseClass = 'component__login-popover';
var LoginPopover = function () { return (_jsxs("div", __assign({ className: baseClass }, { children: [_jsx("h1", __assign({ className: baseClass + "__title" }, { children: "Join the Party" }), void 0), _jsxs("p", __assign({ className: baseClass + "__blurb" }, { children: ["ZagNation gathers here during games to chat Zag basketball! Sign up or log in to join the party.", _jsx("br", {}, void 0), _jsx("br", {}, void 0), _jsx("em", { children: "Please keep chat respectful and appropriate." }, void 0)] }), void 0), _jsxs("div", __assign({ className: baseClass + "__auth-options" }, { children: [_jsx(Link, __assign({ name: "Register" }, { children: "Sign Up" }), void 0), _jsx(Link, __assign({ name: "Login" }, { children: "Log In" }), void 0)] }), void 0)] }), void 0)); };
export default LoginPopover;
