var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect } from "react";
import { useRouter, useResponse } from "@curi/react-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import ProfileProvider from '__state/Profile';
import "./App.scss";
import { Toaster } from 'react-hot-toast';
var authRoutes = ['Login', 'Register', 'ResetPassword'];
var openRoutes = ['Party'];
var App = function () {
    var queryClient = new QueryClient();
    var router = useRouter();
    var response = useResponse().response;
    var Body = response.body, data = response.data, location = response.location, name = response.name;
    useEffect(function () {
        var isAuthPage = authRoutes.includes(name);
        var isOpenPage = openRoutes.includes(name);
        var isLoggedIn = localStorage.getItem('profile') !== null;
        setTimeout(function () {
            if (!isOpenPage) {
                if (isAuthPage && isLoggedIn) {
                    var url = router.url({ name: "Party" });
                    router.navigate({ url: url });
                }
                else if (!isAuthPage && !isLoggedIn) {
                    var url = router.url({ name: "Login" });
                    router.navigate({ url: url });
                }
            }
        }, 0);
    }, [name]);
    return (_jsxs("div", __assign({ className: "app-wrapper" }, { children: [_jsx(Toaster, { position: "top-center", containerClassName: "app-toast" }, void 0), _jsx(QueryClientProvider, __assign({ client: queryClient }, { children: _jsx(ProfileProvider, { children: _jsx(Body, { data: data, location: location }, void 0) }, void 0) }), void 0)] }), void 0));
};
export default App;
