var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect, useState, useContext } from 'react';
import { ProfileContext } from '__state/Profile';
import { getUserData, getLikesAndDislikes, likeOrDislikeMessage } from 'services';
import { formattedUserName, formattedUserYear } from 'utils/helpers';
import ProfilePhoto from 'components/ProfilePhoto';
import ButtonMenuMore from '__assets/images/button__ellipsis-horizontal.svg';
import ButtonThumbsDown from '__assets/images/button__thumbs-down.svg';
import ButtonThumbsUp from '__assets/images/button__thumbs-up.svg';
import './ChatMessage.scss';
var baseClass = 'component__watchparty-chat-message';
var ChatMessage = function (_a) {
    var message = _a.message, isLast = _a.isLast, party = _a.party, setIdForMoreMenu = _a.setIdForMoreMenu;
    var profile = useContext(ProfileContext).profile;
    var _b = useState(), fromUser = _b[0], setFromUser = _b[1];
    var _c = useState(''), fromUserName = _c[0], setFromUserName = _c[1];
    var _d = useState(''), fromUserYear = _d[0], setFromUserYear = _d[1];
    var _e = useState(), fromUserPhoto = _e[0], setFromUserPhoto = _e[1];
    var _f = useState(false), didCurrentUserLike = _f[0], setDidCurrentUserLike = _f[1];
    var _g = useState(false), didCurrentUserDislike = _g[0], setDidCurrentUserDislike = _g[1];
    useEffect(function () {
        var getUser = function () { return __awaiter(void 0, void 0, void 0, function () {
            var docSnap, user, name, year, error_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, getUserData(message.from)];
                    case 1:
                        docSnap = _a.sent();
                        user = docSnap.data();
                        name = formattedUserName(user);
                        year = user.yearGraduated ? formattedUserYear(user.yearGraduated) : null;
                        setFromUser(user);
                        setFromUserName(name);
                        setFromUserPhoto(user.photoURL || (party === null || party === void 0 ? void 0 : party.defaultUserImage));
                        if (year) {
                            setFromUserYear(year);
                        }
                        return [3 /*break*/, 3];
                    case 2:
                        error_1 = _a.sent();
                        console.error(error_1);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        }); };
        var getReactions = function () { return __awaiter(void 0, void 0, void 0, function () {
            var _a, likes, dislikes, error_2;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _b.trys.push([0, 3, , 4]);
                        if (!message.id) return [3 /*break*/, 2];
                        return [4 /*yield*/, getLikesAndDislikes(message.id)];
                    case 1:
                        _a = _b.sent(), likes = _a.likes, dislikes = _a.dislikes;
                        if (profile) {
                            setDidCurrentUserLike(likes.includes(profile.id));
                            setDidCurrentUserDislike(dislikes.includes(profile.id));
                        }
                        _b.label = 2;
                    case 2: return [3 /*break*/, 4];
                    case 3:
                        error_2 = _b.sent();
                        console.error(error_2);
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        }); };
        getUser();
        getReactions();
    }, [message]);
    var handleReaction = function (isLike) { return function (e) { return __awaiter(void 0, void 0, void 0, function () {
        var isNewReaction, error_3;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    e.preventDefault();
                    if (!profile) {
                        return [2 /*return*/, false];
                    }
                    isNewReaction = true;
                    if ((isLike && didCurrentUserLike) || (!isLike && didCurrentUserDislike)) {
                        isNewReaction = false;
                    }
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, likeOrDislikeMessage({
                            messageId: message.id,
                            isLike: isLike,
                            isNewReaction: isNewReaction,
                            userId: profile.id,
                        })];
                case 2:
                    _a.sent();
                    return [3 /*break*/, 4];
                case 3:
                    error_3 = _a.sent();
                    console.log(error_3);
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    }); }; };
    return (_jsxs("div", __assign({ className: baseClass, "data-is-last": isLast }, { children: [fromUser && (_jsx(ProfilePhoto, { photoURL: fromUserPhoto, firstName: fromUser.firstName, lastName: fromUser.lastName, size: "small" }, void 0)), _jsxs("div", __assign({ className: baseClass + "__message-content" }, { children: [_jsxs("div", __assign({ className: "content" }, { children: [_jsxs("p", __assign({ className: "user-name" }, { children: [fromUserName, fromUserYear && (_jsxs(_Fragment, { children: [_jsx("span", {}, void 0), "'", fromUserYear] }, void 0))] }), void 0), message.image ? (_jsx("div", __assign({ className: "message-image" }, { children: _jsx("img", { alt: "", src: message.image }, void 0) }), void 0)) : (_jsx("p", { className: "user-message", dangerouslySetInnerHTML: { __html: message.text } }, void 0))] }), void 0), _jsxs("div", __assign({ className: "actions" }, { children: [_jsxs("div", __assign({ className: "primary" }, { children: [_jsxs("button", __assign({ onClick: function (e) { return handleReaction(true)(e); } }, { children: [_jsx("img", { alt: "Like this message", src: ButtonThumbsUp }, void 0), _jsx(_Fragment, { children: message.likeCount || 0 }, void 0)] }), void 0), _jsxs("button", __assign({ onClick: function (e) { return handleReaction(false)(e); } }, { children: [_jsx("img", { alt: "Dislike this message", src: ButtonThumbsDown }, void 0), _jsx(_Fragment, { children: message.dislikeCount || 0 }, void 0)] }), void 0)] }), void 0), _jsx("div", __assign({ className: "more" }, { children: _jsx("button", __assign({ onClick: function () { return setIdForMoreMenu(message.id); } }, { children: _jsx("img", { alt: "More actions for this message", src: ButtonMenuMore }, void 0) }), void 0) }), void 0)] }), void 0)] }), void 0)] }), void 0));
};
export default ChatMessage;
