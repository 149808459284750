import { prepareRoutes } from "@curi/router";
import Party from "pages/WatchParty";
import Register from "pages/Register";
import RegisterProfile from "pages/RegisterProfile";
import RegisterPhoto from "pages/RegisterPhoto";
import Login from "pages/Login";
import ResetPassword from "pages/ResetPassword";
import Profile from "pages/Profile";
import Settings from "pages/Settings";
export default prepareRoutes([
    {
        name: "Party",
        path: "",
        respond: function () {
            return { body: Party };
        },
    },
    {
        name: "Register",
        path: "register",
        respond: function () {
            return { body: Register };
        },
    },
    {
        name: "RegisterProfile",
        path: "register/profile",
        respond: function () {
            return { body: RegisterProfile };
        },
    },
    {
        name: "RegisterPhoto",
        path: "register/photo",
        respond: function () {
            return { body: RegisterPhoto };
        },
    },
    {
        name: "Login",
        path: "login",
        respond: function () {
            return { body: Login };
        },
    },
    {
        name: "ResetPassword",
        path: "reset-password",
        respond: function () {
            return { body: ResetPassword };
        },
    },
    {
        name: "Profile",
        path: "profile",
        respond: function () {
            return { body: Profile };
        },
    },
    {
        name: "Settings",
        path: "settings",
        respond: function () {
            return { body: Settings };
        },
    },
    // {
    //   name: 'NotFound',
    //   path: '(.*)',
    //   respond() {
    //     return {
    //       body: NotFound
    //     };
    //   }
    // },
]);
